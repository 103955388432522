@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure the base elements have a white background */
html,
body {
  background-color: white;
  height: 100%; /* Full viewport height */
  margin: 0;
  padding: 0;
}

/* Root container styling */
#root {
  min-height: 100vh; /* Ensure full viewport height for the root */
  display: flex;
  flex-direction: column; /* Flex layout for stacking child elements */
  background-color: white; /* White background for root container */
}

/* Main content area */
main {
  flex: 1; /* Allow the main content area to grow */
  overflow-y: auto; /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling with bounce on iOS */
  background-color: white; /* Ensure main has a white background */
}
